.ant-notification {
  z-index: 1051 !important;
}
.ant-spin-nested-loading > div .ant-spin-spinning {
  height: 200px;
}
.ant-tooltip-content {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
}
.ant-tooltip-content .ant-tooltip-arrow-content {
  width: 8px;
  height: 8px;
  background-color: #b9b9b9 !important;
}
.ant-tooltip-content .ant-tooltip-inner {
  color: rgba(0, 0, 0, 0.65);
  background-color: #b9b9b9 !important;
}
.display-inline-flex {
  display: inline-flex !important;
  align-self: center;
  align-items: center;
}
.align-center {
  align-items: center;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.border {
  border: 1px solid #d9d9d9;
}
.border-right {
  border-right: 1px solid #d9d9d9;
}
.border-left {
  border-left: 1px solid #d9d9d9;
}
.border-top {
  border-top: 1px solid #d9d9d9;
}
.border-bottom {
  border-bottom: 1px solid #d9d9d9;
}
.cursor__default {
  cursor: default;
}
.cursor__default.ant-btn {
  cursor: default;
}
.ant-layout-rtl .border-left {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-layout-rtl .border-right {
  border-left: 1px solid #d9d9d9;
  border-right: 0;
}
body {
  font-family: sans-serif !important;
}
.maps-zones__tooltip {
  width: 220px;
  border: 2px solid #bfbfbf;
  background-color: #ffffff;
  border-radius: 10px;
  flex-direction: column;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  font-size: 11px;
}
.maps-zones__tooltip::before,
.maps-zones__tooltip::after {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  left: 45%;
}
.maps-zones__tooltip::before {
  border-top-color: #bfbfbf;
  border-width: 11px;
}
.maps-zones__tooltip::after {
  border-top-color: #ffffff;
  border-width: 11px;
  margin-top: -2px;
}
.maps-zones__tooltip__photo {
  padding: 5px;
  border-bottom: 1px solid #bfbfbf;
}
.maps-zones__tooltip__row {
  flex-direction: column;
}
.maps-zones__tooltip__flex {
  display: flex;
  justify-content: space-between;
}
.map-controls__dot {
  width: 8px;
  height: 8px;
  background-color: #4caf50;
  border-radius: 50%;
}
.map-controls__dot__offline {
  background-color: #f34436;
}
.map-controls__dot__safezone {
  background-color: #0000ff;
}
.map-controls__dot__acknowledged {
  background-color: #eed202;
}
.map-controls__dot__not_responded {
  background-color: #f0813c;
}
.no-padding {
  padding: 0 !important;
}
.no-border {
  border: none !important;
}
